import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

import style from './HistoryOderItem.module.scss';

import { StoreIcon } from '~/components/Icons';
import Button from '~/components/Button';
import formatDateTime from '~/utils/formatDateTime';
import { cancelOrder } from '~/apiService/orderSevice';
import { deleteOrder as cancel } from '~/features/orderSlice';
import { addProductToCart } from '~/apiService/cartService';

const cx = classNames.bind(style);

function HistoryOderItem({ data }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleCancelOrder = () => {
    dispatch(cancelOrder(data?._id)).then((result) => {
      if (result.payload.code === 200) {
        toast.success(result.payload.message);

        // Khi người dùng xóa thành công thì dispatch id đơn hàng để cập nhật lại danh sách đơn hàng
        dispatch(cancel(data?._id));
      } else {
        toast.error(result.payload.message);
      }
    });
  };

  const handleRepurchase = async () => {
    try {
      const promises = data.cartDetails.map((product) => {
        return dispatch(addProductToCart({ product: product.product._id, quantity: product.quantity }));
      });
      await Promise.all(promises);
      toast.success(t('historyOrder.notify01'));
    } catch (err) {
      toast.error(t('historyOrder.notify02'));
    }
  };

  const handleStatus = (status) => {
    if (!status) return;

    if (status === 'pending') {
      return { status: t('historyOrder.statusPending'), color: 'green' };
    } else if (status === 'canceled') {
      return { status: t('historyOrder.statusCanceled'), color: 'red' };
    } else if (status === 'confirmed') {
      return { status: t('historyOrder.statusConfirmed'), color: 'green' };
    } else if (status === 'reject') {
      return { status: t('historyOrder.statusRejected'), color: 'red' };
    } else if (status === 'shipping') {
      return { status: t('historyOrder.statusShipping'), color: 'green' };
    } else if (status === 'success') {
      return { status: t('historyOrder.statusSuccess'), color: 'green' };
    }
  };

  const handlePaymentMethods = (method) => {
    if (!method) return;

    if (method === 'cod') {
      return t('checkout.desc02');
    }
    if (method === 'bank') {
      return t('checkout.desc03');
    }
    if (method === 'prepaid') {
      return t('checkout.desc14');
    }
  };

  return (
    <div className={cx('item-wrapper')}>
      <div className={cx('item__shop-info')}>
        <div className={cx('item__shop-name')}>
          <StoreIcon />
          <h4>{data?.shop.fullname}</h4>
        </div>

        <p className={cx('item__status', { 'item__status--red': handleStatus(data?.status).color === 'red' })}>
          {handleStatus(data?.status).status}
        </p>
      </div>

      {data?.cartDetails.map((cartDetail, index) => {
        return (
          <div key={index} className={cx('sub-item__info-container')}>
            <img className={cx('sub-item__img')} src={cartDetail.product.image} alt="Mocca Cafe" />

            <div className={cx('sub-item__info')}>
              <h4 className={cx('sub-item__name')}>{cartDetail.product.name}</h4>
              <p className={cx('sub-item__desc')}>{cartDetail.product.description}</p>
              <span className={cx('sub-item__quantity')}>X {cartDetail.quantity}</span>
              <p className={cx('sub-item__categories')}>{cartDetail.product.categories}</p>
            </div>

            <span className={cx('sub-item__price')}>{cartDetail.product.price.toLocaleString('vi-VI') + ' đ'}</span>
          </div>
        );
      })}

      <div className={cx('address__container')}>
        <span className={cx('address__label')}>{t('historyOrder.label02')}</span>
        <p className={cx('address__value')}>{data?.address} </p>
      </div>

      <div className={cx('note__container')}>
        <span className={cx('note__label')}>{t('historyOrder.label03')}</span>
        <p className={cx('note__value')}>{data?.note} </p>
      </div>

      <div className={cx('date-create__container')}>
        <span className={cx('date-create__label')}>{t('historyOrder.label04')}</span>
        <p className={cx('date-create__value')}>{formatDateTime(data?.createdAt)} </p>
      </div>

      <div className={cx('payment-method__container')}>
        <span className={cx('payment-method__label')}>{t('checkout.title12')}:</span>
        <p className={cx('payment-method__value')}>{data && handlePaymentMethods(data.paymentMethod)}</p>
      </div>

      <div className={cx('total__container')}>
        <div className={cx('total__content')}>
          <span className={cx('total__label')}>{t('historyOrder.label01')}</span>
          <span className={cx('total__value')}>{data?.totalMoney.toLocaleString('vi-VI') + ' đ'}</span>
        </div>

        <Button
          onClick={() => {
            if (data?.status === 'pending') {
              handleCancelOrder();
            } else {
              handleRepurchase();
            }
          }}
          className={cx('total__container-btn')}
          order
          primary
          cancel={data?.status === 'pending'}
        >
          {data?.status === 'pending' ? t('profile.btn-cancel') : t('button.btn18')}
        </Button>
      </div>
    </div>
  );
}

export default HistoryOderItem;
