import React, { memo } from 'react';
import classNames from 'classnames/bind';
import Cookies from 'js-cookie';
import { Link, useLocation } from 'react-router-dom';

import styles from './BreadCrumb.module.scss';

import { ChevronRight } from '~/components/Icons';
import routes from '~/config/routes';

const cx = classNames.bind(styles);

function BreadCrumb({ className }) {
  const language = Cookies.get('lang');
  const categorySelected = sessionStorage.getItem('categorySelected')
    ? JSON.parse(sessionStorage.getItem('categorySelected'))
    : null;

  const restaurantSelected = sessionStorage.getItem('restaurantSelected')
    ? JSON.parse(sessionStorage.getItem('restaurantSelected'))
    : null;

  const productSelected = sessionStorage.getItem('productSelected')
    ? JSON.parse(sessionStorage.getItem('productSelected'))
    : null;

  let resString = 'Cửa hàng';
  let categoriesString = 'Danh mục';
  let homeString = 'Trang chủ';
  let pathString = '';
  let pathSlug = '';

  if (language === 'en') {
    resString = 'stores';
    categoriesString = 'categories';
    homeString = 'Home';
  }

  if (language === 'zh') {
    resString = '店铺';
    categoriesString = '类别';
    homeString = '主页';
  }

  const pathname = useLocation()
    .pathname.split('/')
    .map((path) => {
      if (path === categorySelected?.slug ? categorySelected.slug : '') {
        return categorySelected.name;
      }
      if (path === restaurantSelected?.slug ? restaurantSelected.slug : '') {
        pathString = restaurantSelected?.name;
        pathSlug = restaurantSelected?.slug;
        return restaurantSelected.name;
      }
      if (path === productSelected?.slug ? productSelected.slug : '') {
        return productSelected.name;
      }
      return path;
    });

  const upperFirstCase = (path) => {
    if (['categories'].includes(path)) {
      path = categoriesString;
    }
    if (path === 'restaurants' || path === 'restaurant') {
      path = resString;
    }

    return path.charAt(0).toUpperCase() + path.slice(1);
  };

  return (
    <div className={cx('breadcrumb', className)}>
      {pathname.map((path, index) => {
        const isRestaurantOrCategory =
          path === routes.category.split('/')[0] || path === routes.restaurant.split('/')[1];
        let linkPath = `/${isRestaurantOrCategory ? 'restaurants' : path}`;
        if (path === pathString) {
          linkPath = '/restaurant/' + pathSlug;
        }

        return (
          <div key={index} className={cx('breadcrumb__item-container')}>
            {index === pathname.length - 1 ? (
              <div className={cx('breadcrumb__item')}>{upperFirstCase(path)}</div>
            ) : (
              <Link className={cx('breadcrumb__item')} to={index === 0 ? '/' : linkPath}>
                {index === 0 ? homeString : upperFirstCase(path)}
              </Link>
            )}

            {index !== pathname.length - 1 && <ChevronRight className={cx('breadcrumb__icon')} />}
          </div>
        );
      })}
    </div>
  );
}

export default memo(BreadCrumb);
