import classNames from 'classnames/bind';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import style from './ProductCard.module.scss';

import { openQuantityDrawer } from '~/features/productSlice';
import { ViewIcon } from '../Icons';

const cx = classNames.bind(style);

function ProductCard({ data, className }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div
      className={cx('product__wrapper', className)}
      onClick={() => {
        //show QuantityDrawer
        dispatch(openQuantityDrawer(data));
        sessionStorage.setItem('productSelected', JSON.stringify({ name: data?.name, slug: data?.slug }))
        sessionStorage.setItem('productIDSelected', JSON.stringify(data?._id))
        navigate(`${location.pathname}/${data.slug}`)
      }}
    >
      <div className={cx('product__img-container')}>
        <img className={cx('product__img')} src={data?.image} alt={data?.name} />
      </div>

      <div className={cx('product__info')}>
        <h4 className={cx('product__name')}>{data?.name}</h4>
        <p className={cx('product__desc')}>{data?.description}</p>

        <div className={cx('product__last-row')}>
          <span className={cx('product__price')}>{data?.price.toLocaleString('vi-VN')} ₫</span>
          <div className={cx('product__add-cart-btn')}>
            <ViewIcon />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductCard;
