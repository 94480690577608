const images = {
  logoVip1: require('~/assets/images/logo-vip1.png'),
  avatarDefault: require('~/assets/images/logo.png'),
  cafe1: require('~/assets/images/banner/cafe01.jpg'),
  cafe2: require('~/assets/images/banner/cafe02.jpg'),
  cafe3: require('~/assets/images/banner/cafe03.jpeg'),
  cafe4: require('~/assets/images/banner/cafe04.jpg'),
  cafe5: require('~/assets/images/banner/cafe05.jpg'),
  cafe6: require('~/assets/images/banner/cafe06.jpeg'),
  cafe7: require('~/assets/images/banner/cafe07.jpeg'),
  appStore: require('~/assets/images/app-store.svg').default,
  googlePlay: require('~/assets/images/google-play.svg').default,
  googleBard: require('~/assets/images/google_Bard_logo.png'),
  coffeeLeaves: require('~/assets/images/coffee-leaves.png'),
  homeInfo1: require('~/assets/images/home/info-1.png'),
  homeInfo2: require('~/assets/images/home/info-2.png'),
  homeInfo3: require('~/assets/images/home/info-3.png'),
  paperBg: require('~/assets/images/home/paper_bg.jpg'),
  curatedCafes: require('~/assets/images/home/curated-cafes.png'),
  leavesBg: require('~/assets/images/home/leaves_pattern.png'),
  moreApp: require('~/assets/images/home/more-app.png'),
  cart: require('~/assets/images/cart.svg').default,
  notFound: require('~/assets/images/error_images/404.png'),
  forbidden: require('~/assets/images/error_images/403.png'),
  internalServer: require('~/assets/images/error_images/500.png'),
  rocket: require('~/assets/images/error_images/rocket.svg').default,
  earth: require('~/assets/images/error_images/earth.svg').default,
  moon: require('~/assets/images/error_images/moon.png'),
  simple: require('~/assets/images/about/simple.png'),
  happy: require('~/assets/images/about/happy.png'),
  together: require('~/assets/images/about/together.png'),
  trip1: require('~/assets/images/about/trip1.png'),
  trip2: require('~/assets/images/about/trip2.jpg'),
  devBg2: require('~/assets/images/about/bg2.svg').default,
  vi: require('~/assets/images/languages/vi.png'),
  en: require('~/assets/images/languages/en.png'),
  zh: require('~/assets/images/languages/zh.png'),
  korea: require('~/assets/images/languages/korea.png'),
  defaultImg: require('~/assets/images/default-img.png'),
  emptyOrder: require('~/assets/images/empty-order.png'),
  qrPay: require('~/assets/images/checkout/qr-payment.png'),
  emptyBox: require('~/assets/images/empty-box.png'),
  logoTpBank: require('~/assets/images/vpBankLogo.png'),
};

export default images;
