import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Box, CircularProgress, Divider, Typography } from '@mui/material';

import styles from './Sidebar.module.scss';

import Conversation from './Conversation';
import { getRandomEmoji } from '~/Layouts/components/Chats/ChatMessage/utils/emojis';
import { useChatContext } from '~/Layouts/components/Chats/ChatMessage/context/ChatContext';
import useGetConversations from '~/Layouts/components/Chats/ChatMessage/hooks/useGetConversations';

const cx = classNames.bind(styles);

const Sidebar = () => {
  const { t } = useTranslation();
  const user = JSON.parse(localStorage.getItem('user'));

  const { newConversation } = useChatContext();
  const { conversations, loading } = useGetConversations();

  if (newConversation) {
    const isExist =
      newConversation._id === user._id ||
      conversations.some((conversation) => conversation._id === newConversation._id);
    if (!isExist) {
      conversations.unshift(newConversation);
    }
  }

  return (
    <Box className={cx('sidebar')}>
      <Divider className={cx('sidebar__separate')} sx={{ mt: 2, mb: 2 }} />

      {/* Conversations */}
      {loading ? (
        <div className={cx('sidebar__loading')}>
          <CircularProgress style={{ color: 'var(--primary-bg)' }} size={22} />
          <Typography className={cx('sidebar__loading-desc')} variant="body2">
            {t('chatMessage.desc01')}
          </Typography>
        </div>
      ) : (
        <div className={cx('sidebar__content')}>
          {conversations.map((conversation, idx) => (
            <div key={conversation._id}>
              <Conversation
                conversation={conversation}
                emoji={getRandomEmoji()}
                lastIdx={idx === conversations.length - 1}
              />
              {idx !== conversations.length - 1 && <Divider />}
            </div>
          ))}
        </div>
      )}
    </Box>
  );
};

export default Sidebar;
