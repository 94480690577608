import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames/bind';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

import styles from './ChatBotModal.module.scss';

import { callApi } from '~/apiService/apiUtils';
import images from '~/assets/images';
import { CloseIcon } from '~/components/Icons';

const cx = classNames.bind(styles);

const ChatBotModal = ({ closeModal }) => {
  const { t } = useTranslation();

  const [messages, setMessages] = useState([]);
  const [messageInput, setMessageInput] = useState('');
  const [loading, setLoading] = useState(false);

  const boxRef = useRef(null);

  const saveMessagesToSessionStorage = (messages) => {
    sessionStorage.setItem('chatMessages', JSON.stringify(messages));
  };

  const handleChange = (event) => {
    setMessageInput(event.target.value);
  };

  const handleSendMessage = async () => {
    if (messageInput) {
      const newMessage = { user: 'User', message: messageInput };
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      setMessageInput('');
      setLoading(true);

      try {
        const customHeaders = {
          'accept-language': `${Cookies.get('lang')}`,
        };

        const response = await callApi('post', `/v1/chat-bots`, null, { message: messageInput }, customHeaders);

        const { data } = response;
        const chatbotResponse = { user: 'Chatbot', message: data };
        setMessages((prevMessages) => [...prevMessages, chatbotResponse]);
      } catch (error) {
        console.error('Error fetching chatbot response:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleKeyPress = async (event) => {
    if (event.key === 'Enter') {
      handleSendMessage();
    }
  };

  const handleWheel = (e) => {
    if (boxRef.current) {
      boxRef.current.scrollTop += e.deltaY * 0.5;
    }
  };

  useEffect(() => {
    const savedMessages = JSON.parse(sessionStorage.getItem('chatMessages'));
    if (savedMessages) {
      setMessages(savedMessages);
    }
  }, []);

  useEffect(() => {
    saveMessagesToSessionStorage(messages);
  }, [messages]);

  return (
    <div className={cx('chat-modal')}>
      <div className={cx('chat-modal__group')}>
        <Typography variant="h6" className={cx('chat-modal__title')}>
          {t('chatBot.title01')}
        </Typography>

        <img loading="lazy" src={images.googleBard} alt="Google bard" className={cx('chat-modal__icon')} />

        <div className={cx('chat-modal__close')} onClick={() => closeModal()}>
          <CloseIcon />
        </div>
      </div>

      <div className={cx('chat-modal__messages')} ref={boxRef} onWheel={handleWheel}>
        {messages.length > 0 &&
          messages.map((message, key) => (
            <div key={key}>
              <Typography
                variant="body1"
                fontSize={{ fontSize: 15 }}
                className={cx(
                  'chat-modal__message',
                  message.user === 'User' ? 'chat-modal__message--user' : 'chat-modal__message--chatbot',
                )}
              >
                {message.message}
              </Typography>
            </div>
          ))}

        {loading && (
          <div className={cx('chat-modal__loading')}>
            <div className={cx('dot')}></div>
            <div className={cx('dot')}></div>
            <div className={cx('dot')}></div>
          </div>
        )}
      </div>

      <hr className={cx('chat-modal__divider')} />

      <div className={cx('chat-modal__container')}>
        <input
          type="text"
          className={cx('chat-modal__input')}
          value={messageInput}
          onChange={handleChange}
          placeholder={t('chatBot.desc01')}
          onKeyDown={handleKeyPress}
        />
        <button type="submit" onClick={handleSendMessage} className={cx('chat-modal__button')}>
          <SendIcon />
        </button>
      </div>
    </div>
  );
};

export default ChatBotModal;
