import React, { createContext, useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import io from 'socket.io-client';

import hostname from '~/utils/http';

const SocketContext = createContext();

export const useSocketContext = () => useContext(SocketContext);

export const SocketContextProvider = ({ children }) => {
  const authUser = useSelector((state) => state.auth.user);

  const [socket, setSocket] = useState(null);
  const [onlineUsers, setOnlineUsers] = useState([]);

  useEffect(() => {
    if (authUser) {
      const newSocket = io(hostname, {
        query: { userId: authUser._id },
      });

      newSocket.on('getOnlineUsers', setOnlineUsers);

      setSocket(newSocket);

      return () => {
        newSocket.close();
      };
    } else if (socket) {
      socket.close();
      setSocket(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);

  return <SocketContext.Provider value={{ socket, onlineUsers }}>{children}</SocketContext.Provider>;
};
