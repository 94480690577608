import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import style from './ListResult.module.scss';

import NoResult from '~/components/NoResult';
import ProductCard from '~/components/ProductCard';
import RestaurantCard from '~/components/RestaurantCard';

const cx = classNames.bind(style);

function ListResult({ data, className, onChangePage }) {
  const { t } = useTranslation();
  const reduxData = useSelector((prop) => prop.product);

  if (reduxData?.shops.length === 0 && reduxData?.products.length === 0 && !reduxData.loading) {
    return <NoResult />;
  }

  return (
    <div className={cx('list')}>
      <h2 className={cx('list-title')}>{t('list-result.list-products')}</h2>
      {reduxData.products.length !== 0 ? (
        <div className={cx('list-container')}>
          <div className={cx('row g-5 g-0')}>
            {reduxData?.products.map((item, index) => {
              return (
                <div key={index} className={cx('col-xl-4 col-12')}>
                  <ProductCard data={item} />
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <NoResult type="product" />
      )}

      <h2 className={cx('list-title')}>{t('list-result.list-restaurants')}</h2>
      {reduxData.shops.length !== 0 ? (
        <div className={cx('list-container')}>
          <div className={cx('row g-5 g-0')}>
            {reduxData?.shops.map((item, index) => {
              return (
                <div key={index} className={cx('col-xl-3 col-6')}>
                  <RestaurantCard data={item} />
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <NoResult />
      )}
    </div>
  );
}

export default ListResult;
