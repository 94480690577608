import { useState, useRef, useEffect } from 'react';
import classNames from 'classnames/bind';
import styled from 'styled-components';
import Picker from 'emoji-picker-react';
import { useTranslation } from 'react-i18next';
import { BsEmojiSmileFill, BsSend, BsImageFill } from 'react-icons/bs';
import { IconButton, InputAdornment, TextField, CircularProgress } from '@mui/material';

import styles from './MessageInput.module.scss';

import useSendMessage from '~/Layouts/components/Chats/ChatMessage/hooks/useSendMessage';

const cx = classNames.bind(styles);
const StyledTextField = styled(TextField)`
  & .MuiOutlinedInput-root {
    border-radius: 0.5rem;
    & fieldset {
      border-color: transparent;
    }
    &:hover fieldset {
      border-color: transparent;
    }
    &.Mui-focused fieldset {
      border-color: transparent;
    }
    & .MuiOutlinedInput-notchedOutline {
      border-color: transparent;
    }
  }
  & .MuiOutlinedInput-input {
    padding: 8px 0 8px 12px;
    font-size: 1.6rem;
    &::placeholder {
      font-size: 1.5rem;
      font-weight: 500;
    }
  }
`;

const MessageInput = ({ conversationId }) => {
  const { t } = useTranslation();
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [message, setMessage] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const { loading, sendMessage } = useSendMessage();
  const emojiPickerRef = useRef(null);
  const fileInputRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!message && !imageFile) return;
    await sendMessage(message, imageFile ? imageFile.file : null);
    setMessage('');
    setImageFile(null);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target)) {
        setShowEmojiPicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setImageFile(null);
  }, [conversationId]);

  const handleEmojiPickerHideShow = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };

  const handleEmojiClick = (emojiObject, event) => {
    if (emojiObject && emojiObject.emoji) {
      setMessage((prevMessage) => prevMessage + emojiObject.emoji);
    }
  };

  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setImageFile(file ? { url: URL.createObjectURL(file), file } : null);
  };

  const handleImagePreviewClick = () => {
    setImageFile(null);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };
  return (
    <div className={cx('message-input__container')}>
      <div className={cx('message-input__buttons')}>
        <div className={cx('message-input__buttons-emoji')} ref={emojiPickerRef}>
          <BsImageFill onClick={handleImageClick} />
          <BsEmojiSmileFill onClick={handleEmojiPickerHideShow} />
          {showEmojiPicker && (
            <div className={cx('message-input__buttons-emoji-picker')}>
              <Picker onEmojiClick={handleEmojiClick} style={{ width: '100%' }} />
            </div>
          )}
        </div>
      </div>

      <form className={cx('message-input__form', 'px-2 my-3')} onSubmit={handleSubmit}>
        <div className={cx('message-input__input-group')}>
          {imageFile && (
            <div className={cx('message-input__preview-image')} onClick={handleImagePreviewClick}>
              <img
                src={imageFile.url}
                className={cx('message-input__image')}
                alt="Preview"
                style={{ maxHeight: '65px', marginTop: '10px', borderRadius: '0.5rem' }}
              />
            </div>
          )}

          <StyledTextField
            className="input"
            variant="outlined"
            fullWidth
            placeholder={t('chatMessage.desc04')}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={handleKeyDown}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    type="submit"
                    disabled={loading}
                    style={{ color: 'var(--primary-bg)', fontSize: '1.6rem' }}
                  >
                    {loading ? (
                      <CircularProgress size={20} style={{ color: 'var(--primary-bg)' }} />
                    ) : (
                      <BsSend className="w-5 h-5 text-white opacity-50" size={18} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>

        <input
          type="file"
          accept="image/*"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
      </form>
    </div>
  );
};

export default MessageInput;
