import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import { TiMessages } from 'react-icons/ti';
import { useTranslation } from 'react-i18next';
import { Avatar, Typography } from '@mui/material';

import styles from './MessageContainer.module.scss';

import Messages from './Messages';
import MessageInput from './MessageInput';
import useConversation from '~/Layouts/components/Chats/ChatMessage/zustand/useConversation';
import { CloseIcon } from '~/components/Icons';

const cx = classNames.bind(styles);

const NoChatSelected = () => {
  const { t } = useTranslation();

  const authUser = useSelector((state) => state.auth.user);

  return (
    <div className={cx('no-chat')}>
      <Typography variant="body1" component="h6" style={{ fontWeight: 'bold', fontSize: '1.5rem' }}>
        {t('chatMessage.desc02')} 👋 {authUser?.fullname} ❄
      </Typography>

      <Typography variant="body1" component="p" style={{ fontSize: '1.3rem' }}>
        {t('chatMessage.desc03')}
      </Typography>

      <TiMessages style={{ fontSize: '4rem' }} className="text-3xl md:text-6xl text-center" />
    </div>
  );
};

const MessageContainer = ({ closeModal }) => {
  const { selectedConversation, setSelectedConversation } = useConversation();

  useEffect(() => {
    return () => {
      setSelectedConversation(null);
    };
  }, [setSelectedConversation]);
  return (
    <div className={cx('container')}>
      <div className={cx('container__close')} onClick={() => closeModal()}>
        <CloseIcon />
      </div>
      
      {!selectedConversation ? (
        <NoChatSelected />
      ) : (
        <>
          <div className={cx('container__heading')}>
            <Typography variant="body1" component="span" style={{ fontWeight: 'bold', fontSize: '1.5rem' }}>
              <Avatar className={cx('container__avatar')} src={selectedConversation.avatar} alt="Avatar" />
            </Typography>

            <Typography
              variant="body1"
              component="span"
              style={{ fontWeight: 'bold', fontSize: '1.5rem', marginLeft: '1rem' }}
            >
              {selectedConversation.fullname}
            </Typography>
          </div>

          <Messages />
          <MessageInput conversationId={selectedConversation._id} />
        </>
      )}
    </div>
  );
};

export default MessageContainer;
