import { useEffect, useRef } from 'react';
import classNames from 'classnames/bind';
import { Box, Typography } from '@mui/material';

import styles from './Messages.module.scss';

import Message from './Message';
import useGetMessages from '~/Layouts/components/Chats/ChatMessage/hooks/useGetMessages';
import useListenMessages from '~/Layouts/components/Chats/ChatMessage/hooks/useListenMessages';
import MessageSkeleton from '~/Layouts/components/Chats/ChatMessage/skeletons/MessageSkeleton';

const cx = classNames.bind(styles);

const Messages = () => {
  const { messages, loading } = useGetMessages();
  useListenMessages();

  const lastMessageRef = useRef();
  const boxRef = useRef(null);

  const handleWheel = (e) => {
    if (boxRef.current) {
      boxRef.current.scrollTop += e.deltaY * 0.5;
    }
  };

  useEffect(() => {
    setTimeout(() => {
      lastMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  }, [messages]);

  return (
    <Box ref={boxRef} onWheel={handleWheel} className={cx('messages__box', 'px-4')}>
      {!loading &&
        messages.length > 0 &&
        messages.map((message, index) => (
          <div key={message._id} ref={index === messages.length - 1 ? lastMessageRef : null}>
            <Message message={message} />
          </div>
        ))}

      {loading && [...Array(3)].map((_, idx) => <MessageSkeleton key={idx} />)}

      {!loading && messages.length === 0 && (
        <Typography variant="body1" component="p" className={cx('messages__desc')}>
          Send a message to start the conversation
        </Typography>
      )}
    </Box>
  );
};

export default Messages;
