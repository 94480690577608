const routes = {
  home: '/',
  category: 'categories/:category',
  restaurants: '/restaurants',
  restaurant: '/restaurant/:restaurant',
  productDetail: '/restaurant/:restaurantP/:productID',
  login: '/auth/login',
  signup: '/auth/signup',
  forgotPassword: '/auth/forgot-password',
  verifyOTP: '/auth/verify-otp',
  loginWith2Fa: 'auth/login-with-2fa',
  resetPassword: '/auth/reset-password',
  aboutMoccaCafe: '/about/mocca-cafe',
  profile: '/auth/profile',
  checkout: '/checkout',
  payment: '/payment',
  forbidden: '/forbidden',
  internalServer: '/server-error',
  forgotPasswordOTP: '/auth/verify-otp-forgot-password',
  notFound: '*',
};

export default routes;
