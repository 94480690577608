import { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Oval } from '@agney/react-loading';
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@mui/material';

import styles from './CheckOut.module.scss';

import Button from '~/components/Button';
import images from '~/assets/images';
import routes from '~/config/routes';
import CartItem from '~/components/CartItem';
import { createOrder } from '~/apiService/ordersService';
import { savePayment } from '~/features/paymentSlice';
import { AddressIcon, ArrowDownIcon, InfoIcon, NoteIcon } from '~/components/Icons';

const cx = classNames.bind(styles);

function CheckOut() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.orders.loading);
  const checkoutCartsData = useSelector((state) => state.checkoutCarts.selectedShops);

  const [listCartProduct, setListCartProduct] = useState([]);
  const [totalCartCheckout, setTotalCartCheckout] = useState(0);
  const cartsLength = checkoutCartsData ? checkoutCartsData.length : 0;

  const [cityDistricts, setCityDistricts] = useState([]);
  const [districtWards, setDistrictWards] = useState([]);

  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [wards, setWards] = useState([]);

  // Shopping cart information - start
  const [city, setCity] = useState(t('checkout.title04'));
  const [district, setDistrict] = useState(t('checkout.title05'));
  const [ward, setWard] = useState(t('checkout.title06'));
  const [addressDetail, setAddressDetail] = useState('');
  const [note, setNote] = useState(t('checkout.desc01'));
  const [payment, setPayment] = useState('cod');
  const [listProduct, setListProduct] = useState([]);
  // - end

  const [showCities, setShowCities] = useState(false);
  const [isSelectCity, setIsSelectCity] = useState(false);
  const [showDistricts, setShowDistricts] = useState(false);
  const [isSelectDistrict, setIsSelectDistrict] = useState(false);
  const [showWards, setShowWards] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isAddressDetail, setIsAddressDetail] = useState(false);
  const [isChangeAddressDetail, setIsChangeAddressDetail] = useState(false);

  const citiesRef = useRef(null);
  const addressCitiesRef = useRef(null);
  const districtsRef = useRef(null);
  const addressDistrictsRef = useRef(null);
  const wardsRef = useRef(null);
  const addressWardsRef = useRef(null);

  const handleClickOutsideCities = useCallback((event) => {
    if (
      citiesRef.current &&
      !citiesRef.current.contains(event.target) &&
      !addressCitiesRef.current.contains(event.target)
    ) {
      setShowCities(false);
    }
  }, []);

  const handleClickOutsideDistricts = useCallback((event) => {
    if (
      districtsRef.current &&
      !districtsRef.current.contains(event.target) &&
      !addressDistrictsRef.current.contains(event.target)
    ) {
      setShowDistricts(false);
    }
  }, []);

  const handleClickOutsideWards = useCallback((event) => {
    if (
      wardsRef.current &&
      !wardsRef.current.contains(event.target) &&
      !addressWardsRef.current.contains(event.target)
    ) {
      setShowWards(false);
    }
  }, []);

  const handleCityClick = (cityItem) => {
    setCity(`${cityItem.name_with_type}`);
    const filteredDistricts = districts.filter((district) => district.parent_code === `${cityItem.code}`);
    setCityDistricts(filteredDistricts);
  };

  const handleDistrictClick = (districtItem) => {
    setDistrict(`${districtItem.name_with_type}`);
    const filteredWards = wards.filter((ward) => ward.parent_code === `${districtItem.code}`);
    setDistrictWards(filteredWards);
  };

  const handleChangePayment = (event) => {
    setPayment(event.target.value);
  };

  const handleOrder = () => {
    const editedAddress =
      ward === t('checkout.title15')
        ? [addressDetail, district, city].join(', ')
        : [addressDetail, ward, district, city].join(', ');

    dispatch(
      createOrder({
        cartDetails: listProduct,
        paymentMethod: payment,
        address: editedAddress,
        note: note,
      }),
    ).then((result) => {
      if (result.payload.code === 201) {
        setIsSubmit(false);
        setListCartProduct([]);
        setTotalCartCheckout(0);
        setCity(t('checkout.title04'));
        setDistrict(t('checkout.title05'));
        setWard(t('checkout.title06'));
        toast.success(result.payload.message);
        if (payment === 'cod' || payment === 'prepaid') {
          setTimeout(() => {
            navigate(routes.profile);
            toast.info(t('checkout.notify04'));
          }, 4000);
        }
        if (payment === 'bank') {
          dispatch(
            savePayment({
              qr: result.payload.data.urlQRCode,
              total: result.payload.data.totalMoneyOrder,
            }),
          );
          setTimeout(() => {
            navigate('/payment');
            toast.info(t('checkout.notify05'));
          }, 3000);
        }
      } else {
        toast.warning(result.payload.message);
      }
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Lấy các tỉnh/thành phố
  useEffect(() => {
    fetch('/data/cities.json')
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const citiesArray = Object.values(data).sort((a, b) => a.name.localeCompare(b.name));
        setCities(citiesArray);
      })
      .catch((error) => console.error('Error fetching JSON:', error));
  }, []);

  // Lấy các quận/huyện
  useEffect(() => {
    fetch('/data/districts.json')
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const districtsArray = Object.values(data).sort((a, b) => a.name.localeCompare(b.name));
        setDistricts(districtsArray);
      })
      .catch((error) => console.error('Error fetching JSON:', error));
  }, []);

  // Lấy các xã/thị trấn
  useEffect(() => {
    fetch('/data/wards.json')
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const wardsArray = Object.values(data).sort((a, b) => a.name.localeCompare(b.name));
        setWards(wardsArray);
      })
      .catch((error) => console.error('Error fetching JSON:', error));
  }, []);

  useEffect(() => {
    if (districtWards.length === 0 && district !== t('checkout.title05')) {
      setWard(t('checkout.title15'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [districtWards]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideCities);

    return () => {
      document.removeEventListener('click', handleClickOutsideCities);
    };
  }, [handleClickOutsideCities]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideDistricts);

    return () => {
      document.removeEventListener('click', handleClickOutsideDistricts);
    };
  }, [handleClickOutsideDistricts]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideWards);

    return () => {
      document.removeEventListener('click', handleClickOutsideWards);
    };
  }, [handleClickOutsideWards]);

  useEffect(() => {
    if (city !== t('checkout.title04')) {
      setIsSelectCity(true);
    } else {
      setIsSelectCity(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [city]);

  useEffect(() => {
    if (district !== t('checkout.title05')) {
      setIsSelectDistrict(true);
    } else {
      setIsSelectDistrict(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [district]);

  useEffect(() => {
    if (
      city !== t('checkout.title04') &&
      district !== t('checkout.title05') &&
      ward !== t('checkout.title06') &&
      addressDetail !== ''
    ) {
      setIsSubmit(true);
    } else {
      setIsSubmit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [city, district, ward, addressDetail]);

  // Kiểm tra hiển thị lỗi nhập số nhà
  useEffect(() => {
    if (addressDetail === '' && isChangeAddressDetail) {
      setIsAddressDetail(false);
    } else {
      setIsAddressDetail(true);
    }
  }, [addressDetail, isChangeAddressDetail]);

  useEffect(() => {
    if (checkoutCartsData) {
      const productIds = [];

      setListCartProduct(checkoutCartsData);

      checkoutCartsData.forEach((cartItem) => {
        setTotalCartCheckout((prevState) => prevState + cartItem.totalMoney);
        cartItem.selectedProducts.forEach((cartItemDetail) => {
          const productId = cartItemDetail._id;
          productIds.push(productId);
        });
      });
      setListProduct(productIds);

      if (checkoutCartsData.length === 0) {
        toast.warning(t('checkout.notify03'));
      }
    }

    return () => setTotalCartCheckout(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutCartsData]);

  return (
    <div className={cx('checkout')}>
      {cartsLength > 0 && (
        <>
          <div className={cx('checkout__top')}>
            <div className={cx('container gx-5')}>
              <div className={cx('checkout__info')}>
                <div>
                  <h1 className={cx('checkout__heading')}>{t('checkout.heading')}</h1>
                  <h4 className={cx('checkout__name')}>
                    {listCartProduct
                      ? listCartProduct.map((cartItem, index) => {
                          if (index === listCartProduct.length - 1) {
                            return cartItem.shop.fullname;
                          }
                          return `${cartItem.shop.fullname}, `;
                        })
                      : ' '}
                  </h4>
                </div>
              </div>
            </div>
          </div>

          <div className={cx('container gx-5')}>
            <div className={cx('row gx-4 gx-xxl-5')}>
              <div className={cx('col-12 col-xxl-8 col-xl-8 col-lg-8 col-md-12')}>
                <div className={cx('checkout__group')}>
                  <h4 className={cx('checkout__group-title')}>{t('checkout.title01')}</h4>
                  <div
                    className={cx('separate')}
                    style={{ '--separate-bg': '#d1d3d6', '--separate-mg': '12px 0 20px' }}
                  ></div>

                  <div className={cx('delivery-time')}>
                    <span className={cx('delivery-time__title')}>{t('checkout.title02')}</span>
                    <span className={cx('delivery-time__value')}> 1 - 2 {t('cart.desc05')}</span>
                  </div>
                  <div
                    className={cx('separate')}
                    style={{ '--separate-bg': '#d1d3d6', '--separate-mg': '20px 0' }}
                  ></div>

                  <div className={cx('address')}>
                    <span className={cx('address__title')}>{t('checkout.title03')}</span>
                    <div className={cx('address__group', 'address__group--three')}>
                      {/* Cities */}
                      <div
                        ref={addressCitiesRef}
                        onClick={() => setShowCities(!showCities)}
                        className={cx('address__city', showCities && 'address__city--dropdown')}
                      >
                        <span className={cx(showCities && 'address__city-title')}>{city}</span>
                        <ArrowDownIcon className={cx('address__arrow', showCities && 'address__arrow--show')} />
                        <ul ref={citiesRef} className={cx('address__city-list', showCities && 'address__city--show')}>
                          {cities.map((cityItem, index) => (
                            <li
                              onClick={() => {
                                handleCityClick(cityItem);
                                if (city !== t('checkout.title04')) {
                                  setDistrict(t('checkout.title05'));
                                  setWard(t('checkout.title06'));
                                  if (districtsRef.current) {
                                    districtsRef.current.scrollTop = 0;
                                  }
                                }
                              }}
                              key={index}
                              className={cx('address__city-item')}
                            >
                              {`${cityItem.name_with_type}`}
                              {city === `${cityItem.name_with_type}` && (
                                <span className={cx('address__city-checked')}></span>
                              )}
                            </li>
                          ))}
                        </ul>
                      </div>

                      {/* Districts */}
                      <div
                        ref={addressDistrictsRef}
                        onClick={() => {
                          if (isSelectCity) {
                            setShowDistricts(!showDistricts);
                          } else {
                            toast.info(t('checkout.notify01'));
                          }
                        }}
                        style={!isSelectCity ? { opacity: '0.6', cursor: 'no-drop' } : {}}
                        className={cx('address__district', showDistricts && 'address__district--dropdown')}
                      >
                        <span className={cx(showDistricts && 'address__district-title')}>{district}</span>
                        <ArrowDownIcon className={cx('address__arrow', showDistricts && 'address__arrow--show')} />
                        <ul
                          ref={districtsRef}
                          className={cx('address__district-list', showDistricts && 'address__district--show')}
                        >
                          {cityDistricts.map((districtItem, index) => (
                            <li
                              onClick={() => {
                                handleDistrictClick(districtItem);
                                if (district !== t('checkout.title05')) {
                                  setWard(t('checkout.title06'));
                                  if (wardsRef.current) {
                                    wardsRef.current.scrollTop = 0;
                                  }
                                }
                              }}
                              key={index}
                              className={cx('address__district-item')}
                            >
                              {`${districtItem.name_with_type}`}
                              {district === `${districtItem.name_with_type}` && (
                                <span className={cx('address__district-checked')}></span>
                              )}
                            </li>
                          ))}
                        </ul>
                      </div>

                      {/* Wards */}
                      <div
                        ref={addressWardsRef}
                        onClick={() => {
                          if (isSelectDistrict) {
                            setShowWards(!showWards);
                          } else {
                            toast.info(t('checkout.notify02'));
                          }
                        }}
                        style={!isSelectDistrict ? { opacity: '0.6', cursor: 'no-drop' } : {}}
                        className={cx('address__ward', showWards && 'address__ward--dropdown')}
                      >
                        <span className={cx(showWards && 'address__ward-title')}>{ward}</span>
                        <ArrowDownIcon className={cx('address__arrow', showWards && 'address__arrow--show')} />
                        <ul ref={wardsRef} className={cx('address__ward-list', showWards && 'address__ward--show')}>
                          {(districtWards || []).map((wardItem, index) => (
                            <li
                              onClick={() => setWard(`${wardItem.name_with_type}`)}
                              key={index}
                              className={cx('address__ward-item')}
                            >
                              {`${wardItem.name_with_type}`}
                              {ward === `${wardItem.name_with_type}` && (
                                <span className={cx('address__ward-checked')}></span>
                              )}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>

                    {/* Address detail */}
                    <span className={cx('address__title', 'address__title-detail')}>{t('checkout.title14')}</span>
                    <div className={cx('address__group')}>
                      <div
                        className={cx('form__text-input', 'form__text-input--sm', 'address__detail-input')}
                        style={!isAddressDetail ? { border: '1px solid #f44336' } : {}}
                      >
                        <input
                          value={addressDetail}
                          onChange={(e) => {
                            setAddressDetail(e.target.value);
                            setIsChangeAddressDetail(true);
                          }}
                          type="text"
                          name="address-detail"
                          placeholder={t('checkout.desc07')}
                          className={cx('form__input')}
                        />
                        <AddressIcon className={cx('form__input-icon', !isAddressDetail && 'form__input-icon--err')} />
                      </div>
                      <p className={cx('form__error')}>{!isAddressDetail && 'Vui lòng nhập Số nhà, Ngõ, Đường'}</p>
                    </div>

                    {/* Note */}
                    <span className={cx('address__title', 'address__title-detail')}>{t('checkout.title07')}</span>
                    <div className={cx('address__group')}>
                      <div className={cx('form__text-input', 'form__text-input--sm', 'address__detail-input')}>
                        <input
                          value={note}
                          onChange={(e) => setNote(e.target.value)}
                          type="text"
                          name="note"
                          placeholder={t('checkout.desc01')}
                          className={cx('form__input')}
                        />
                        <NoteIcon className={cx('form__input-icon')} />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Products list */}
                <div className={cx('checkout__group')}>
                  <h4 className={cx('checkout__group-title')}>{t('checkout.title08')}</h4>
                  <div
                    className={cx('separate')}
                    style={{ '--separate-bg': '#d1d3d6', '--separate-mg': '12px 0 20px' }}
                  ></div>

                  <div className={cx('checkout__carts')}>
                    {listCartProduct &&
                      listCartProduct.map((cartItem, index) => {
                        return (
                          <div key={index} className={cx('cart__products')}>
                            <div className={cx('cart__products-top')}>
                              <Link to={'#!'}>
                                <h5 className={cx('cart__products-heading')}>{cartItem.shop.fullname}</h5>
                              </Link>
                            </div>
                            <div className={cx('cart__products-list')}>
                              {cartItem.selectedProducts.map((cartDetail, index) => (
                                <CartItem key={index} data={cartDetail} isCheckout />
                              ))}
                            </div>
                            <div className={cx('cart__summary')}>
                              <div className={cx('cart__summary-info')}>
                                <span className={cx('cart__summary-price')}>{t('cart.desc03')}</span>
                                <span className={cx('cart__summary-price')}>
                                  {cartItem.totalMoney.toLocaleString('vi-VN')} ₫
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>

                  <div className={cx('checkout__total')}>
                    <div className={cx('checkout__total-group')}>
                      <h6 className={cx('checkout__total-title')}>{t('checkout.title09')}</h6>
                      <h6 className={cx('checkout__total-value')}>
                        {`${totalCartCheckout.toLocaleString('vi-VN')} ₫`}
                      </h6>
                    </div>
                    <div className={cx('checkout__total-group')}>
                      <h6 className={cx('checkout__total-title')}>
                        {t('checkout.title12')} <InfoIcon />
                      </h6>
                      <h6 className={cx('checkout__total-value')}>0 ₫</h6>
                    </div>
                  </div>
                </div>
              </div>

              <div className={cx('col-12 col-xxl-4 col-xl-4 col-lg-4 col-md-12')}>
                {/* Payment detail */}
                <div className={cx('checkout__group')}>
                  <h4 className={cx('checkout__group-title')}>{t('checkout.title10')}</h4>
                  <div
                    className={cx('separate')}
                    style={{ '--separate-bg': '#d1d3d6', '--separate-mg': '12px 0 20px' }}
                  ></div>
                  <div className={cx('checkout__payment')}>
                    <div className={cx('checkout__payment-group')}>
                      <FormControl component="fieldset" style={{ fontFamily: 'var(--font-family)' }}>
                        <FormLabel component="legend" className="checkout__pay-title">
                          {t('checkout.title11')}
                        </FormLabel>
                        <RadioGroup
                          aria-label="payment-method"
                          defaultValue="cod"
                          name="radio-buttons-group"
                          onChange={handleChangePayment}
                        >
                          <FormControlLabel
                            value="cod"
                            control={<Radio className="radio-button" />}
                            label={t('checkout.desc02')}
                            className="radio-label"
                          />
                          <FormControlLabel
                            value="bank"
                            control={<Radio className="radio-button" />}
                            label={t('checkout.desc03')}
                            className="radio-label"
                          />
                          <FormControlLabel
                            value="prepaid"
                            control={<Radio className="radio-button" />}
                            label={t('checkout.desc04')}
                            className="radio-label"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>
                </div>

                <div className={cx('checkout__right')}>
                  <div className={cx('checkout__right-info')}>
                    <h4 className={cx('checkout__right-title')}>{t('cart.desc03')}</h4>
                    <span className={cx('checkout__right-cost')}>
                      {`${totalCartCheckout.toLocaleString('vi-VN')} ₫`}
                    </span>
                  </div>
                  <Button onClick={handleOrder} disabled={!isSubmit || listCartProduct.length === 0} order primary>
                    {t('button.btn16')}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {cartsLength <= 0 && (
        <div className={cx('no-products')}>
          <img src={images.cart} alt="cart" className={cx('no-products__thumb')} />
          <div className={cx('no-products__info')}>
            <h4 className={cx('no-products__title')}>{t('checkout.title13')}</h4>
            <p className={cx('no-products__desc')}>{t('checkout.desc06')}</p>
            <Link to={routes.restaurants} className={cx('no-products__link')}>
              {t('checkout.link01')}
            </Link>
          </div>
        </div>
      )}

      {isLoading && (
        <div className={cx('modal-loading')}>
          <div className={cx('modal-loading__content')}>
            <Oval width="50" color="#4d8b55" />
          </div>
        </div>
      )}
    </div>
  );
}

export default CheckOut;
