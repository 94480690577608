import { useEffect, useState } from 'react';
import classNames from 'classnames/bind';

import styles from './GoToTop.module.scss';

import { ArrowDownIcon } from '~/components/Icons';

const cx = classNames.bind(styles);

function GoToTop() {
  const [isVisible, setIsVisible] = useState(false);
  const [progressScroll, setProgressScroll] = useState(0);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    const checkScroll = () => {
      if (!isVisible && window.scrollY > 0) {
        setIsVisible(true);
      }
      if (isVisible && window.scrollY === 0) {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', checkScroll);

    return () => window.removeEventListener('scroll', checkScroll);
  }, [isVisible]);

  useEffect(() => {
    const checkProgressScroll = () => {
      var scrollTop = window.scrollY || document.documentElement.scrollTop;
      var scrollHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      var scrollPercent = (scrollTop / scrollHeight) * 100;
      var scrollDegrees = (scrollPercent / 100) * 360;
      setProgressScroll(scrollDegrees.toFixed(2));
    };

    window.addEventListener('scroll', checkProgressScroll);

    return () => window.removeEventListener('scroll', checkProgressScroll);
  }, []);

  return (
    <div
      className={cx('go-top__wrapper', isVisible && 'go-top__wrapper--show')}
      style={{ '--progressScroll': `${progressScroll}deg` }}
    >
      <div className={cx('go-top__content', isVisible && 'go-top__content--show')} onClick={scrollToTop}>
        <ArrowDownIcon className={cx('go-top__icon')} />
      </div>
    </div>
  );
}

export default GoToTop;
